import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import http, { IFetchParams, _fetch } from 'utils/http';
import * as Sentry from '@sentry/react';
import { ProviderPractice } from 'pages/SuperAdmin/types';
import { UserRole } from 'types/NavTreeModel';
import useLocalStorage from 'hooks/use-local-storage';
import { CacheKeys } from 'utils/constants';
import { Designation } from 'types/Entitites';
import { fetchUserMetadata } from 'pages/Dashboard/services/provider.services';
import { UserMetadataFlags } from 'pages/Dashboard/types';
import { mixpanelEventInstance, mixpanelSessionReplay } from 'pages/Rewards/mixpanelInstances';

type UserMap = Record<UserRole, boolean>;

type FetchContextValues = {
  http: typeof http;
  providerId: string;
  updateActiveProvider: (providerId: ProviderPractice) => void;
  superAdminId: string;
  activeProvider: ProviderPractice;
  isSuggestScalesEnabled: boolean;
  isBillingAdmin: boolean;
  userRoles: UserMap;
  isNewVersionEnabled: boolean;
};

export const FetchContext = React.createContext<FetchContextValues>({
  http: { ...http },
  providerId: '',
  updateActiveProvider: () => null,
  superAdminId: '',
  activeProvider: {} as ProviderPractice,
  isSuggestScalesEnabled: false,
  isBillingAdmin: false,
  userRoles: {} as UserMap,
  isNewVersionEnabled: false,
});

type FetchContextProviderProps = {
  children: React.ReactNode;
};

export function FetchContextProvider({ children }: FetchContextProviderProps) {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [providerId, setProviderId] = React.useState('');
  const [superAdminId, setSuperAdminId] = React.useState('');
  const [activeProvider, setActiveProvider] = React.useState({} as ProviderPractice);
  const [isSuggestScalesEnabled] = React.useState(false);
  const [userRoles, setUserRoles] = React.useState<UserMap>({} as UserMap);
  const [localProvider, setLocalProvider] = useLocalStorage(CacheKeys.providerId);
  const [isNewVersionEnabled, setIsNewVersionEnabled] = React.useState(false);

  const parsedLocalProvider = React.useMemo(
    () => JSON.parse(localProvider ?? '{}'),
    [localProvider],
  );

  React.useEffect(() => {
    if (parsedLocalProvider?.providerId && parsedLocalProvider?.providerId !== providerId) {
      const { providerId: id, providerName, providerDesignation } = parsedLocalProvider;
      setProviderId(id);
      setActiveProvider({
        providerId: id,
        providerName,
        providerDesignation,
      } as ProviderPractice);
    }
  }, [parsedLocalProvider, providerId]);

  const isBillingAdmin = React.useMemo(
    () => userRoles[UserRole.BILLING_ADMIN]
      || activeProvider.providerDesignation === Designation.PROVIDER_DESIGNATION_BILLING_ADMIN,
    [activeProvider.providerDesignation, userRoles],
  );

  const fetchWithToken = React.useCallback(
    async (url: string, params: IFetchParams) => {
      const authToken = await getAccessTokenSilently();
      const headers = (params.headers as Headers) ?? {};
      return _fetch(url, {
        ...params,
        headers: {
          Authorization: `Bearer ${authToken}`,
          ...headers,
        } as Partial<RequestInit>,
      });
    },
    [getAccessTokenSilently],
  );

  const httpModule = React.useMemo(
    () => ({
      delete: async (url: string) => fetchWithToken(url, {
        method: 'DELETE',
      }),
      get: async (url: string) => fetchWithToken(url, {
        method: 'GET',
      }),
      patch: async (url: string, payload: object) => fetchWithToken(url, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),

      post: async (url: string, payload: object, params?: IFetchParams) => fetchWithToken(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        ...(params ?? {}),
      }),

      put: async (url: string, payload: object) => fetchWithToken(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
      }),
    }),
    [fetchWithToken],
  );

  const updateActiveProvider = React.useCallback(
    (provider: ProviderPractice) => {
      const { providerId: id, providerName, providerDesignation } = provider;
      setLocalProvider(JSON.stringify({ providerId: id, providerName, providerDesignation }));
      setProviderId(provider.providerId);
      setActiveProvider(provider);
    },
    [setLocalProvider],
  );

  const getNewVersionFeatureFlag = React.useCallback(async (userId: string) => {
    try {
      const patientScalesEnabledResponse = await fetchUserMetadata(httpModule.post, {
        name: UserMetadataFlags.NewVersionFeatureFlag,
        userId,
      })();
      setIsNewVersionEnabled(patientScalesEnabledResponse.flag?.value === 'true');
    }
    catch (err) {
      setIsNewVersionEnabled(false);
    }
  }, [httpModule.post]);

  const setProviderIdFromClaims = React.useCallback(async () => {
    const idTokenClaims = await getIdTokenClaims();
    const roles = idTokenClaims?.['headlamp/roles'];
    const rolesMap = roles.reduce(
      (acc: UserMap, role: UserRole) => ({ ...acc, [role]: true }),
      {} as UserMap,
    );
    const hasSuperAdminRole = roles.includes(UserRole.SUPER_ADMIN);
    const userProviderId = idTokenClaims?.provider_id ?? '';
    const selectedProviderId = hasSuperAdminRole
      ? parsedLocalProvider?.providerId ?? userProviderId
      : userProviderId;
    setUserRoles(rolesMap);
    setProviderId(selectedProviderId);
    getNewVersionFeatureFlag(selectedProviderId);
    // TODO : Uncomment this when this feature starts working
    // getPatientManifestFeatureFlag(userProviderId);
    setSuperAdminId(hasSuperAdminRole ? userProviderId : '');
    Sentry.setUser({
      email: idTokenClaims?.email,
      id: userProviderId,
      device: navigator?.userAgent ?? '',
      deviceHeight: window?.innerHeight ?? '',
      deviceWidth: window?.innerWidth ?? '',
    });
    mixpanelEventInstance.identify(userProviderId);
    mixpanelEventInstance.people.set({
      name: idTokenClaims?.name,
      type: 'provider',
    });
    mixpanelSessionReplay.identify(userProviderId);
    mixpanelSessionReplay.people.set({
      name: idTokenClaims?.name,
      type: 'provider',
    });
  }, [getIdTokenClaims, getNewVersionFeatureFlag, parsedLocalProvider?.providerId]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setProviderIdFromClaims();
    }
  }, [isAuthenticated, setProviderIdFromClaims]);

  const ctxValue = React.useMemo(
    () => ({
      http: httpModule,
      providerId,
      activeProvider,
      superAdminId,
      isSuggestScalesEnabled,
      isBillingAdmin,
      userRoles,
      isNewVersionEnabled,
      updateActiveProvider,
    }),
    [
      httpModule,
      providerId,
      activeProvider,
      superAdminId,
      isSuggestScalesEnabled,
      isBillingAdmin,
      userRoles,
      isNewVersionEnabled,
      updateActiveProvider,
    ],
  );

  return <FetchContext.Provider value={ctxValue}>{children}</FetchContext.Provider>;
}

export function useHttp() {
  const values = React.useContext(FetchContext);
  if (!values) {
    throw Error('Component is not inside FetchContext Provider');
  }
  return { ...values };
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import UserSettings from 'components/Layouts/UserSettings';
import { LogoUrl } from 'utils/constants';
import { FetchContext } from 'hooks/use-fetch';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { ProviderPractice } from 'pages/SuperAdmin/types';
import Tooltip from '@mui/material/Tooltip';

export default function Header() {
  const theme = useTheme();
  const navigator = useNavigate();
  const {
    activeProvider,
    superAdminId,
    updateActiveProvider,
  } = React.useContext(FetchContext);

  const isSuperAdmin = !!superAdminId;

  const handleActiveProviderClick = () => {
    updateActiveProvider({ providerId: superAdminId } as ProviderPractice);
    navigator('/providers-list');
  };

  return (
    <AppBar
      position='fixed'
      sx={{
        maxHeight: theme.custom.appBarHeight,
        background: theme.palette.background.default,
      }}
    >
      <Toolbar disableGutters>
        <Grid container mx='12.5px' justifyContent='space-between'>
          <Grid item>
            <img
              height={theme.custom.logoHeight}
              width={theme.custom.logoWidth}
              src={LogoUrl.lg}
              alt=''
            />
          </Grid>
          <Grid item>
            {isSuperAdmin && activeProvider?.providerName && (
              <Tooltip title='Change active provider'>
                <Chip
                  sx={{
                    mr: 3,
                  }}
                  color='primary'
                  label={(
                    <div>
                      <b>
                        Active provider :
                      </b>
                      {' '}
                      {activeProvider.providerName}
                    </div>
                )}
                  variant='outlined'
                  onClick={handleActiveProviderClick}
                />
              </Tooltip>
            )}
            {/* <Rewards /> */}
            <UserSettings />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

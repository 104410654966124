import { Alert, Box, Button, Card, CardContent, Grid, Typography, capitalize } from '@mui/material';
import Input from 'components/Common/Input';
import PhoneNumberInput, { formatPhoneNumber } from 'components/Common/PhoneNumberInput';
import { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import { ShowMoreDrawerChildProps, ShowMoreDrawerTitle } from 'pages/Dashboard/ShowMoreDrawer';
import { RefetchPatientsCtx } from 'pages/Dashboard/constants';
import usePatientList from 'pages/Dashboard/hooks/usePatientList';
import fetchPracticeList from 'pages/Dashboard/services/addpatient.services';
import { resendPatientInvite } from 'pages/Dashboard/services/patient.services';
import { ResendInvitePayload } from 'pages/Dashboard/types';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { formatEmail } from 'utils/appUtils';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import {
  googlePhoneNumberToPhoneNumberInputString,
  phoneNumberStringToGooglePhoneNumber,
} from 'utils/phoneUtils';

const defaultMobileValidationMessage = {
  text: 'Invite delivery failed because this number is invalid. Please ',
  isHighlighted: false,
};

const defaultEmailValidationMessage = {
  text: 'Invite delivery failed because this email is invalid. Please ',
  isHighlighted: false,
};

interface ResendPatientInviteForm {
  email?: string;
  mobile?: string;
}

interface TProps extends ShowMoreDrawerChildProps {
  patient: PatientObj;
  setToastProps: (props: ToastProps) => void;
}

export default function ResendInviteForm({
  patient,
  drawerItem,
  closeDrawer,
  setToastProps,
}: TProps) {
  const {
    id,
    name: { firstName = '', lastName = '' } = {},
    dateOfBirth,
    email: existingEmail,
    phoneNumber: existingMobile,
    onboardingMetadata,
    provider: { id: providerId = '' } = {},
  } = patient;
  const displayName = `${capitalize(firstName)} ${capitalize(lastName)}`;
  const dob = dateOfBirth ? googleDateToDateString(dateOfBirth as GoogleDate) : '';

  const isMobileError = !!onboardingMetadata?.error?.find((e) => e.code === 1001);
  const isEmailError = !!onboardingMetadata?.error?.find((e) => e.code === 1000);

  const ctx = React.useContext(RefetchPatientsCtx);

  const { http } = useHttp();

  const [practiceId, setPracticeId] = useState('');

  const formMethods = useForm<ResendPatientInviteForm>({
    defaultValues: {
      email: formatEmail(existingEmail),
      mobile: existingMobile
        ? formatPhoneNumber(googlePhoneNumberToPhoneNumberInputString(existingMobile))
        : '',
    },
  });

  const { patientList } = usePatientList(providerId, {}, 'resend-invite-form');

  const isFormEdited = useMemo(() => {
    if (isMobileError && isEmailError) {
      return (
        formMethods?.formState?.dirtyFields?.mobile && formMethods?.formState?.dirtyFields?.email
      );
    }

    if (isMobileError) {
      return formMethods?.formState?.dirtyFields?.mobile;
    }

    if (isEmailError) {
      return formMethods?.formState?.dirtyFields?.email;
    }

    return true;
  }, [isMobileError, isEmailError, formMethods?.formState]);

  const existingRemoteMobileNumberSet = useMemo(() => {
    const existingMobileNumbersSet = new Set();
    patientList.forEach((p) => {
      const formattedPhoneNumber = googlePhoneNumberToPhoneNumberInputString(p.phoneNumber);
      if (p.id !== id && formattedPhoneNumber) {
        existingMobileNumbersSet.add(formatPhoneNumber(formattedPhoneNumber));
      }
    });
    return existingMobileNumbersSet;
  }, [id, patientList]);

  const mobileValidationMessage = useMemo(
    () => [
      defaultMobileValidationMessage,
      {
        text: 'update the mobile number.',
        isHighlighted: true,
      },
    ],
    [],
  );

  const emailValidationMessage = useMemo(
    () => [
      defaultEmailValidationMessage,
      {
        text: 'update or clear this email.',
        isHighlighted: true,
      },
    ],
    [],
  );

  useQuery('practices-list', {
    queryFn: fetchPracticeList(http.get, providerId),
    onSuccess: (data) => {
      if (data.practices?.length > 0) {
        // TODO: Temporary fix until we figure out from where we should pick practiceId
        setPracticeId(data.practices[0].id);
      }
    },
  });

  const closeDrawerAndRefreshPatients = useCallback(() => {
    setToastProps({
      message: 'Invite sent!',
      open: true,
      type: ToastType.success,
    });
    ctx?.refetchPatients();
    closeDrawer();
  }, [closeDrawer, ctx, setToastProps]);

  const resendInviteMutation = useMutation({
    mutationFn: (payload: ResendInvitePayload) => resendPatientInvite(http.post, providerId, {
      ...payload,
    }),
    onSuccess: closeDrawerAndRefreshPatients,
    onError: (error) => {
      setToastProps({
        message: (error as Error)?.message,
        open: true,
        type: ToastType.error,
      });
    },
  });

  const onSubmit: SubmitHandler<ResendPatientInviteForm> = ({ email, mobile }) => {
    const resendInvitationPayload: ResendInvitePayload = {
      patient: [
        {
          id: id ?? '',
          email: email ?? '',
          phoneNumber: mobile ? phoneNumberStringToGooglePhoneNumber(mobile) : undefined,
        },
      ],
      headlampProviderId: providerId,
      practiceId,
    };
    resendInviteMutation.mutate(resendInvitationPayload);
  };

  const validateMobile = useCallback(
    (val: string) => {
      const isEmpty = !val.trim().length;
      const isDuplicateMobile = !isEmpty && existingRemoteMobileNumberSet.has(val);
      if (isDuplicateMobile) {
        return 'Duplicate mobile number';
      }
      if (isEmpty) {
        return 'Mobile number is required';
      }
      return true;
    },
    [existingRemoteMobileNumberSet],
  );

  const validateEmail = useCallback(
    (val: string) => {
      const emailMatchPatient = patientList.find((p) => p.email === val);
      if (emailMatchPatient?.id && emailMatchPatient?.id !== id) {
        return 'Duplicate email address';
      }
      return true;
    },
    [id, patientList],
  );

  return (
    <Card sx={{ height: '100%' }}>
      <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />
      <CardContent>
        <Box display='flex' flexDirection='column' gap='24px'>
          <Typography>
            Resend the Headlamp invite to the patient if they did not receive it, misplaced it, or
            their information is incorrect.
          </Typography>
          <Box>
            <Typography variant='h6' className='fs-mask'>{displayName}</Typography>
            <Typography variant='subtitle1' className='fs-mask'>{dob}</Typography>
          </Box>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PhoneNumberInput
                    name='mobile'
                    control={formMethods.control}
                    label='Mobile phone number'
                    placeholder='Mobile phone number'
                    rules={{
                      validate: validateMobile,
                    }}
                    isMasked
                  />
                  {isMobileError && mobileValidationMessage.length ? (
                    <Alert variant='outlined' severity='error' sx={{ marginTop: '6px' }}>
                      <Typography variant='body2'>
                        {mobileValidationMessage.map((message) => (message.isHighlighted ? (
                          <Typography
                            key={message.text}
                            variant='body2'
                            component='span'
                            sx={{ fontWeight: 700 }}
                          >
                            {message.text}
                          </Typography>
                        ) : (
                          message.text
                        )))}
                      </Typography>
                    </Alert>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Input
                    control={formMethods.control}
                    name='email'
                    label='Email (optional)'
                    textFieldProps={{
                      type: 'email',
                      placeholder: 'Email (optional)',
                    }}
                    rules={{
                      validate: validateEmail,
                    }}
                    isMasked
                  />
                  {isEmailError && emailValidationMessage.length ? (
                    <Alert variant='outlined' severity='error' sx={{ marginTop: '6px' }}>
                      <Typography variant='body2'>
                        {emailValidationMessage.map((message) => (message.isHighlighted ? (
                          <Typography
                            key={message.text}
                            variant='body2'
                            component='span'
                            sx={{ fontWeight: 700 }}
                          >
                            {message.text}
                          </Typography>
                        ) : (
                          message.text
                        )))}
                      </Typography>
                    </Alert>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={
                      resendInviteMutation.isLoading
                      || resendInviteMutation.isSuccess
                      || !isFormEdited
                    }
                  >
                    RESEND INVITE
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </CardContent>
    </Card>
  );
}

import React from 'react';
import { Box, TextField, debounce } from '@mui/material';
import Autocomplete, { Option } from 'pages/ScalesManifest/components/Autocomplete';
import { format } from 'date-fns';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export type TFilters = {
  providerName: Option;
  patientName: Option;
  scale: Option;
  startDate: string;
  endDate: string;
};

interface IFiltersProps {
  filters: TFilters;
  setFilters: React.Dispatch<React.SetStateAction<TFilters>>;
  providerNameOptions: Option[];
  patientNameOptions: Option[];
  scaleOptions: Option[];
  isFetching: boolean;
}

function Filters({
  filters,
  setFilters,
  providerNameOptions,
  patientNameOptions,
  scaleOptions,
  isFetching,
}: IFiltersProps) {
  const { providerName, patientName, scale, startDate } = filters;


  const setFilterChange = React.useCallback((
    key: keyof TFilters,
    value: Option | Date | string,
  ) => {
    trackMixpanelEvent(MixpanelEventName.FILTERS_APPLIED, {
      filter: key,
    });
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  }, [setFilters]);

  const setFiltersValue = (key: keyof TFilters) => (value: Option) => {
    setFilterChange(key, value);
  };

  const setDateFiltersValue = (key: keyof TFilters) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value;
    if (value) {
      const year = parseInt(value.split('-')[0], 10);
      const isValidYear = year > 1900 && year < 2100;
      if (isValidYear) {
        setFilterChange(key, value);
      }
    }
    else {
      setFilterChange(key, '');
    }
  };

  const formattedTodaysDate = React.useMemo(
    () => format(new Date(), 'yyyy-MM-dd'),
    [],
  );

  return (
    <Box display='flex' flexDirection='row' alignItems='flex-start' paddingY={2} gap={2}>
      <Autocomplete
        value={providerName}
        label='Provider name'
        setValue={setFiltersValue('providerName')}
        options={providerNameOptions}
        loading={isFetching}
        disabled={isFetching}
        testId='provider-name-autocomplete'
      />
      <Autocomplete
        value={patientName}
        label='Patient name'
        setValue={setFiltersValue('patientName')}
        options={patientNameOptions}
        loading={isFetching}
        disabled={isFetching}
        className='fs-mask'
      />
      <Autocomplete
        value={scale}
        label='Scale'
        setValue={setFiltersValue('scale')}
        options={scaleOptions}
        loading={isFetching}
        disabled={isFetching}
      />
      <TextField
        label='Start Date'
        placeholder='Start Date'
        type='date'
        size='small'
        InputLabelProps={{ shrink: true }}
        onChange={debounce(setDateFiltersValue('startDate'), 500)}
        inputProps={{ max: formattedTodaysDate }}
        fullWidth
        disabled={isFetching}
      />
      <TextField
        label='End Date'
        type='date'
        size='small'
        InputLabelProps={{ shrink: true }}
        onChange={debounce(setDateFiltersValue('endDate'), 500)}
        inputProps={{ min: startDate || undefined, max: formattedTodaysDate }}
        fullWidth
        disabled={isFetching}
      />
    </Box>
  );
}

export default Filters;

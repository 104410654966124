import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, IconButton, List, ListItem, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Input from 'components/Common/Input';
import Add from '@mui/icons-material/Add';
import { SMSConsent } from 'pages/Dashboard/components/AddPatientConsents';
import PhoneNumberInput from 'components/Common/PhoneNumberInput';
import { PatientInvite } from 'pages/Dashboard/types';
import { googlePhoneNumberToPhoneNumberString } from 'utils/phoneUtils';
import usePatient from 'pages/Dashboard/AddPatientContext';

export default function PatientInviteForm() {
  const { control, formState, watch } = useFormContext();
  const { state } = usePatient();

  const { patientList } = state;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'patients',
    shouldUnregister: true,
    rules: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validate: (fieldArray: Array<any>) => {
        if (fieldArray.every((p) => !p?.mobile?.trim())) {
          return 'Mobile number is required';
        }
        return true;
      },
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <List>
            {fields.map((item, index) => (
              <ListItem key={item.id} sx={{ pb: 2, pt: 0, px: 0 }}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12} className='fs-mask'>
                    <PhoneNumberInput
                      name={`patients.${index}.mobile`}
                      control={control}
                      label='Mobile number'
                      rules={{
                        validate: (val: string) => {
                          const patients: PatientInvite[] = watch('patients');
                          const isRemoteDuplicate = patientList?.find((p) => {
                            const unformattedPhoneNumber = `+1${(val || '').replace(/\D/g, '')}`;
                            return (
                              googlePhoneNumberToPhoneNumberString(p.phoneNumber)
                              === unformattedPhoneNumber
                            );
                          });
                          if (isRemoteDuplicate) {
                            return 'Phone number already registered';
                          }
                          const duplicateIndexes = patients.reduce((indicesArr, patient, idx) => {
                            if (patient.mobile && patient.mobile === val) {
                              return [...indicesArr, idx];
                            }
                            return [...indicesArr];
                          }, [] as Array<number>);
                          if (duplicateIndexes.length < 2) {
                            return true;
                          }
                          if (duplicateIndexes[duplicateIndexes.length - 1] === index) {
                            return 'Duplicate mobile number';
                          }
                          return true;
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className='fs-mask'>
                    <Input
                      control={control}
                      name={`patients.${index}.email`}
                      label='Email address (optional)'
                      textFieldProps={{
                        type: 'email',
                      }}
                      rules={{
                        validate: (val) => {
                          const patients: PatientInvite[] = watch('patients');
                          const isRemoteDuplicate = patientList?.find((p) => p.email === val);
                          if (isRemoteDuplicate) {
                            return 'Email already registered';
                          }
                          const duplicateIndexes = patients.reduce((indicesArr, patient, idx) => {
                            if (patient.email && patient.email === val) {
                              return [...indicesArr, idx];
                            }
                            return [...indicesArr];
                          }, [] as Array<number>);
                          if (duplicateIndexes.length < 2) {
                            return true;
                          }
                          if (duplicateIndexes[duplicateIndexes.length - 1] === index) {
                            return 'Duplicate email address';
                          }
                          return true;
                        },
                      }}
                    />
                  </Grid>
                  {fields.length > 1 && (
                    <Grid
                      item
                      md={2}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mt: 1,
                      }}
                    >
                      <IconButton onClick={() => remove(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            ))}
          </List>
          {formState.errors?.patients?.root?.message ? (
            <Typography variant='body2' color='error'>
              {formState.errors?.patients?.root?.message}
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          <SMSConsent />
        </Grid>
      </Grid>
      <Box sx={{ mt: '24px' }}>
        <Button
          variant='outlined'
          sx={{ width: { xs: '100%', md: 'fit-content' }, height: { xs: '20px', md: '40px' } }}
          startIcon={<Add />}
          onClick={() => append({ mobile: '', email: '' })}
          disabled={fields.length === 10}
        >
          ADD ANOTHER
        </Button>
      </Box>
    </>
  );
}
